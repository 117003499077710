import React from "react"
import SbEditable from "storyblok-react"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import ButtonGroup from "../molecules/ButtonGroup"

// ohne das zusätzliche div um die children funktioniert
// der Live-Edit innerhalb von Storyblock nicht mehr
const BlockWrapper = ({
  className,
  block,
  children,
  blockWidth = "container",
  blockDirection = "vertical",
  blockPadding = "py-block",
  showHeadline = "true",
  centerHeadline = "false",
  showButtons = "false",
}) => {
  blockWidth = block.block_width ? block.block_width : blockWidth
  blockDirection = block.block_type ? block.block_type : blockDirection
  const blockName = block.component
    ? block.component.replace(/_/g, "-")
    : "unknown-block"

  return (
    <SbEditable content={block}>
      <section
        className={`${className ? className : ""} ${blockName} ${
          block.block_bg ? block.block_bg : ""
        }`}
        id={block._uid}
      >
        <div className={`${blockPadding} block-w-${blockWidth}`}>
          <div
            className={`${
              blockWidth === "content" ? "block-w-content-inner" : ""
            }`}
          >
            {blockDirection === "vertical" ? (
              <>
                {showHeadline !== "false" && (
                  <HeadlineEnhanced
                    kicker={block.kicker}
                    headline={block.headline}
                    headlineLevel={block.headline_level}
                    subheadline={block.subheadline}
                    className={centerHeadline === "true" ? "text-center" : ""}
                  />
                )}
                {children}
                {showButtons !== "false" && (
                  <ButtonGroup className="mt-8" buttons={block.buttons} />
                )}
              </>
            ) : (
              <div
                className={`flex flex-row flex-wrap ${
                  blockWidth !== "screen" ? "-mx-grid" : ""
                }`}
              >
                <div className="w-full lg:w-1/3 px-grid">
                  {showHeadline !== "false" && (
                    <HeadlineEnhanced
                      kicker={block.kicker}
                      headline={block.headline}
                      headlineLevel={block.headline_level}
                      subheadline={block.subheadline}
                      className={centerHeadline === "true" ? "text-center" : ""}
                    />
                  )}
                  {showButtons !== "false" && (
                    <ButtonGroup className="mt-8" buttons={block.buttons} />
                  )}
                </div>

                <div
                  className={`w-full lg:w-2/3 ${
                    blockWidth !== "screen" ? "px-grid" : ""
                  }`}
                >
                  {children}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </SbEditable>
  )
}

export default BlockWrapper
